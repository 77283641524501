@font-face {
  font-family: arimo;
  src: url("./fonts/Arimo/Arimo-VariableFont_wght.woff2");
}
/* @font-face {
  font-family: poppins;
  src: url("./fonts/Poppins/Poppins-Thin.ttf");
  font-weight: 100;
} */

@font-face {
  font-family: poppins;
  src: url("./fonts/Poppins/Poppins-ExtraLight.woff2");
  font-weight: 200;
}

@font-face {
  font-family: poppins;
  src: url("./fonts/Poppins/Poppins-Light.woff2");
  font-weight: 300;
}

@font-face {
  font-family: poppins;
  src: url("./fonts/Poppins/Poppins-Regular.woff2");
  font-weight: 400;
}
@font-face {
  font-family: poppins;
  src: url("./fonts/Poppins/Poppins-Medium.woff2");
  font-weight: 500;
}
/* @font-face {
  font-family: poppins;
  src: url("./fonts/Poppins/Poppins-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: poppins;
  src: url("./fonts/Poppins/Poppins-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: poppins;
  src: url("./fonts/Poppins/Poppins-ExtraBold.ttf");
  font-weight: 800;
} */

/* @font-face {
  font-family: gothic;
  src: url("./fonts/Gothic_A1/GothicA1-Thin.ttf");
  font-weight: 100;
}

@font-face {
  font-family: gothic;
  src: url("./fonts/Gothic_A1/GothicA1-ExtraLight.ttf");
  font-weight: 200;
} */

@font-face {
  font-family: gothic;
  src: url("./fonts/Gothic_A1/GothicA1-Light.woff2");
  font-weight: 300;
}
/* 
@font-face {
  font-family: gothic;
  src: url("./fonts/Gothic_A1/GothicA1-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: gothic;
  src: url("./fonts/Gothic_A1/GothicA1-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: gothic;
  src: url("./fonts/Gothic_A1/GothicA1-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: gothic;
  src: url("./fonts/Gothic_A1/GothicA1-Bold.ttf");
  font-weight: 700;
} */
@font-face {
  font-family: gothic;
  src: url("./fonts/Gothic_A1/GothicA1-ExtraBold.woff2");
  font-weight: 800;
}
@font-face {
  font-family: marker;
  src: url("./fonts/Permanent_Marker/PermanentMarker-Regular.woff2");
}

:root {
  --color-1: #ed3237;
  --color-2: #00a859;
  --color-3: #e8e8e8;
  --color-4: #8d8d8d;
  --color-5: #ffad41;

  --color-1-hover: #db282e;
  --color-2-hover: #02904d;
}

.fw-thin {
  font-weight: 100;
}
.fw-extralight {
  font-weight: 200;
}

.slide-btn-animation {
  animation: movedownandup 0.5s infinite forwards alternate linear;
}

@keyframes movedownandup {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(5px);
  }
}
header .social-icons {
  display: none;
}
.header-happy-hour {
  padding: 3.5px 0;
}

.navbar-brand .text-logo {
  font-size: 25px !important;
}
.white-date-picker::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
ul.social-icons a {
  cursor: pointer;
  padding: 3px 7px;
  color: rgb(30, 30, 30);
}

ul.social-icons a:hover {
  background-color: var(--color-2);
  color: white !important;
}

.dark-navbar-bg {
  background-color: rgba(0, 0, 0, 0.569);
}
nav.fixed-scrolled {
  top: 0;
  background-color: #000000d6;
}
nav {
  transition: 0.1s;
  top: 61px;
  z-index: 100;
}

nav .custom-nav-link {
  color: white;
  padding-bottom: 5px;
  margin-bottom: 4px;
  display: inline-block;
}
.custom-nav-link.active {
  border-bottom: 2px solid var(--color-2);
}
nav .custom-nav-link:hover {
  color: var(--color-2);
}
nav .btn.book-a-table {
  padding: 4px 16px !important;
}
nav .btn.book-a-table:hover {
  color: var(--color-1);
}
.text-logo {
  transition: 0.1s;
  cursor: pointer;
}
.text-logo:hover:not(.no-hover) {
  transform: scale(1.2);
}
.index-banner {
  background-image: url(./images/index-banner.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.index-banner-h1 {
  position: relative;
  color: white;
  text-align: center;
  width: 100%;
  font-size: 20px;
}
.index-banner-h1 * {
  position: relative;
  z-index: 2;
}
.index-banner .text-2 {
  font-size: 18px;
}
.bg-transparent-black {
  background-color: rgba(0, 0, 0, 0.448);
}
.index-banner-h1::after {
  content: "";
  display: block;
  background-color: rgba(0, 0, 0, 0.448);
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 43%;
  transform: translateY(-50%);
}

.index-section-1 .about {
  font-size: 12px;
}
.index-section-2 .text-div {
  font-family: "marker";
  z-index: 33;
  width: 131px;
  font-size: 0.4rem;
  right: 7%;
}
.index-section-3 .section-heading {
  font-size: 2rem;
  font-style: italic;
  font-family: serif;
  font-weight: 600;
}

.index-section-6 .section-heading,
.index-section-7 .section-heading {
  font-size: 2.2rem;
}
.bg-light-grey {
  background-color: var(--color-3);
}
.index-section-4 .card-bg-1 > div,
.index-section-4 .card-bg-3 > div {
  background-color: #00000081;
}
.index-section-4 .card-bg-2::after,
.index-section-6 .menu-card::after {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: #00000081;
}
.index-section-6 .menu-card::after {
  background-color: #0000005e;
}
.index-section-4 .card-bg-1,
.index-section-4 .card-bg-2,
.index-section-4 .card-bg-3 {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.index-section-4 .card-bg-1 {
  background-image: url(./images/home/steak.webp);
}
.index-section-4 .card-bg-2 {
  background-image: url(./images/home/indian-food.webp);
}
.index-section-4 .card-bg-3 {
  background-image: url(./images/home/biryani.webp);
}

.index-section-6 {
  color: white;
  background-image: url(./images/home/Group\ 86.webp);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.index-custom-tab-item {
  margin: 0 10px;
}
.index-custom-tab-link {
  color: white !important;
  border-radius: 0 !important;
  border: unset !important;
  padding: 3px 20px 8px;
  width: 200px;
  background-color: #47423f !important;
  position: relative;
}
.index-custom-tab-link > * {
  position: relative;
  z-index: 4;
}
.index-custom-tab-link.active {
  padding: 0 20px 5px;
  border: 3px solid var(--color-5) !important;
}

.index-custom-tab-link > span {
  line-height: 35px;
  font-size: 1.5em;
  font-family: serif;
  margin-bottom: -10px;
  display: inline-block;
}
.index-section-6 .menu-card {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.index-section-6 .menu-card h1 {
  position: relative;
  z-index: 3;
}

.index-section-7 .index-book-table-btn {
  color: var(--color-2);
  border-color: var(--color-2);
  outline: unset !important;
  border-radius: 0;
  border-width: 2px !important;
  min-width: 180px;
}

.index-section-7 .index-book-table-btn:hover {
  color: white;
  background-color: var(--color-2);
}
.index-section-7 .left-card {
  padding-top: 40px;
  padding-bottom: 40px;
}
.custom-btn-green {
  color: white !important;
  border-color: var(--color-2);
  background-color: var(--color-2);
  outline: unset !important;
  border-radius: 0;
  border-width: 2px !important;
}
.custom-btn-green:hover {
  background-color: var(--color-2-hover);
}

.footer-nav {
  padding: 0%;
  list-style: none;
  background-color: #000000a7;
  margin: 0;
  padding: 25px;
}
.footer-nav li a {
  color: rgb(206, 206, 206);
  font-family: gothic;
  font-weight: 800;
  font-size: 18px;
  text-decoration: none;
  display: inline-block;
  padding: 5px 10px;
}

.footer-nav li a.active span {
  border-bottom: 2px solid;
}
.footer-nav li a:hover {
  color: white;
}

.menu-banner {
  background-image: url(./images/menu/our-menu-banner.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.banner-height {
  height: calc(100vh - 100px);
}
.banner-height-2 {
  height: 277px;
}
.menu-border-1 {
  border-color: var(--color-1) !important;
}

.menu-border-2 {
  border-color: var(--color-2) !important;
}
.border-6 {
  border-width: 8px !important;
}
.border-7 {
  border-width: 10px !important;
}
.menu-section .li-hover {
  cursor: pointer;
  padding: 3px 10px;
}
.menu-section .li-hover:hover {
  color: black;
  background-color: var(--color-3);
}

#bookATableModal {
  background-image: url(./images/booking-banner.webp);
  background-position: center;
  background-size: cover;
}
#bookATableModal > div {
  position: relative;
  z-index: 3;
}

#bookATableModal::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000064;
}

#bookATableModal input.custom {
  color: white;
  box-shadow: none !important;
  background-color: unset !important;
  border: unset !important;
  border-radius: 0 !important;
  border-bottom: 2px solid white !important;
}
.contact-us-banner {
  background-image: url(./images/contact-us/contact-us-banner.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.contact-us-section-1 h2 .title {
  width: 65px;
}

nav .navbar-collapse.custom {
  background-color: #1e1e1e;
  padding: 0px 24px;
}

/* width */
.custom-scroll-1::-webkit-scrollbar {
  width: 20px;
}

/* Track */
.custom-scroll-1::-webkit-scrollbar-track {
  border: 1px solid rgb(199, 199, 199);
  border-radius: 10px;
}

/* Handle */
.custom-scroll-1::-webkit-scrollbar-thumb {
  background: var(--color-1);
  border-radius: 10px;
}

/* Handle on hover */
.custom-scroll-1::-webkit-scrollbar-thumb:hover {
  background: var(--color-1-hover);
}

/* width */
.custom-scroll-2::-webkit-scrollbar {
  width: 20px;
}

/* Track */
.custom-scroll-2::-webkit-scrollbar-track {
  border: 1px solid rgb(199, 199, 199);
  border-radius: 10px;
}

/* Handle */
.custom-scroll-2::-webkit-scrollbar-thumb {
  background: var(--color-2);
  border-radius: 10px;
}

/* Handle on hover */
.custom-scroll-2::-webkit-scrollbar-thumb:hover {
  background: var(--color-2-hover);
}

.fa-times {
  cursor: pointer;
}
.fa-times:hover {
  color: var(--color-1-hover);
}
.take-away-nav {
  border: 1px solid rgb(206, 206, 206);
}
.take-away-tab {
  border: 1px solid rgb(206, 206, 206);
  flex-grow: 1;
  text-align: center;
  padding: 5px 20px;
  cursor: pointer;
}
.take-away-tab.active {
  color: white;
  border-color: var(--color-1-hover);
  background-color: var(--color-1-hover);
}
.take-away-tab:hover:not(.active) {
  color: white;
  border-color: var(--color-2);
  background-color: var(--color-2);
}

.basket-btn {
  text-align: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-weight: 600;
  padding: 3px 10px;
  background-color: var(--color-2-hover);
  color: white;
  font-size: 20px;
  position: fixed;
  right: 0;
  bottom: 20px;
  z-index: 3;
  cursor: pointer;
}

.basket {
  position: fixed;
  z-index: 50;
  background-color: white;
  max-width: 500px;
  bottom: 0px;
  width: 100%;
  right: 0;
}
.basket ul {
  max-height: 40vh;
}

.custom_alert {
  max-width: 500px;
  position: relative;
}
.custom_alert .close_btn {
  position: absolute;
  top: 4px;
  right: 9px;
  cursor: pointer;
  transition: 0.1s;
  border-radius: 3px;
  padding: 0 4px;
  border: 1px solid rgba(255, 255, 255, 0);
}
.custom_alert .close_btn:hover {
  border: 1px solid whitesmoke;
  color: whitesmoke;
}

.custom_alert.fixed {
  top: 75px;
  left: 50%;
  position: fixed;
  z-index: 1200;
  transform: translateX(-50%);
  width: 90%;
}
.close {
  user-select: none;
  cursor: pointer;
  transition: 0.3s;
}

.hover-bg-white:hover {
  background-color: honeydew !important;
}
@media (max-width: 321px) {
  .index-banner-h1 {
    font-size: 18px;
  }
}

@media (min-width: 280px) {
  nav {
    top: 61px;
  }
  .index-section-2 .text-div {
    width: 152px;
    font-size: 0.44rem;
    right: 7%;
  }
}

@media (min-width: 329px) {
  .index-section-2 .text-div {
    width: 196px;
    font-size: 0.6rem;
    right: 7%;
  }
}
@media (min-width: 351px) {
  nav {
    top: 43px;
  }
}
@media (min-width: 437.5px) {
  .index-section-2 .text-div {
    width: 229px;
    font-size: 0.7rem;
    right: 10%;
  }
}
@media (max-width: 521px) {
  .index-banner-h1 {
    font-size: 18px;
    /* margin-bottom: -21px !important; */
  }

  .index-custom-tab-link {
    font-size: 12px;
  }
  .position-sm-fixed {
    position: fixed;
  }
}

@media (min-width: 762px) {
  .banner-height,
  .banner-height-2 {
    height: calc(100vh - 43px);
    max-height: 1100px;
  }
  .index-custom-tab-link.active::after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: -5px;
    left: 50%;
    z-index: 3;
    transform-origin: center;
    background-color: #47423f;
    transform: rotate(-45deg) translateX(-50%);
    border-left: 3px solid var(--color-5);
    border-bottom: 3px solid var(--color-5);
  }
  .index-banner-h1 {
    font-size: 40px;
  }
  .index-banner .text-2 {
    font-size: 20px;
  }
  .index-section-1 .about {
    font-size: 15px;
  }
  .index-section-3 .section-heading {
    font-size: 3.2rem;
  }
  .menu-banner {
    background-image: url(./images/menu/our-menu-banner.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

@media (min-width: 500px) {
  .d-xs-flex {
    display: flex !important;
  }
  .index-section-2 .text-div {
    width: 275px;
    font-size: 0.8rem;
    right: 10%;
  }
}
@media (min-width: 519px) {
  header .social-icons {
    display: flex;
  }
}

@media (min-width: 600px) {
  .index-section-2 .text-div {
    width: 336px;
    font-size: 1rem;
    right: 10%;
  }
}

@media (min-width: 762px) {
  .contact-us-section-1 h2 .title {
    width: 65px;
  }
}

@media (min-width: 800px) {
  .index-section-2 .text-div {
    width: 484px;
    font-size: 1.4rem;
    right: 10%;
  }
}
@media (min-width: 992px) {
  /* nav {
    top: 35px;
  } */
  .navbar-brand .text-logo {
    font-size: 30px !important;
  }
  nav .btn.book-a-table {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .index-section-6 .section-heading,
  .index-section-7 .section-heading {
    font-size: 3.2rem;
  }

  .index-section-7 .left-card {
    padding-top: 150px;
    padding-bottom: 150px;
  }
  .fs-lg-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }
  .fs-lg-2 {
    font-size: calc(1.325rem + 0.9vw) !important;
  }
  .fs-lg-3 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }
  .fs-lg-4 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-lg-5 {
    font-size: 1.25rem !important;
  }
  .w-lg-100 {
    width: 100%;
  }
  nav .navbar-collapse.custom {
    background-color: unset;
    padding: 0px;
  }
  .index-section-2 .text-div {
    width: 518px;
    font-size: 1.5rem;
    right: 10%;
  }

  .take-away-2-section-1 .menu-cards {
    max-height: 70vh;
  }
  .basket-btn {
    display: none;
  }
  .basket {
    position: static;
    background-color: white;
    max-width: 700px;
    height: 100%;
  }
  .basket ul {
    max-height: 55vh;
  }

  .footer-logo {
    font-size: 10px !important;
  }
}

@media (min-width: 1100px) {
  .index-section-2 .text-div {
    width: 629px;
    font-size: 1.9rem;
    right: 10%;
  }
}
@media (min-width: 1200px) {
  .index-section-6 .nav {
    justify-content: center;
  }
}
@media (min-width: 1400px) {
  nav {
    top: 43px;
  }
  .index-section-2 .text-div {
    width: 718px;
    font-size: 2.1rem;
    right: 10%;
  }
}

@media (min-width: 1500px) {
  .index-section-2 .text-div {
    width: 818px;
    font-size: 2.5rem;
    right: 10%;
  }
}
@media (min-width: 1800px) {
  .navbar {
    top: 54px;
  }
  .index-section-1 .about {
    font-size: 20px;
  }
  .navbar-brand .text-logo {
    font-size: 35px !important;
  }

  .fs-xxl-3 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }
  .fs-xxl-5 {
    font-size: 1.25rem !important;
  }

  .fs-xxl-6 {
    font-size: 1rem !important;
  }
  .index-section-2 .text-div {
    width: 920px;
    font-size: 2.8rem;
    right: 10%;
  }
}
